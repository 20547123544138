<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br/>
        <br/>
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Campaign</strong>
            </div>
            <div class="text-muted">Edit campaign information</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Campaign's name.</span>
                  </template>
                  <a-input type="text" placeholder="Campaign Name"
                           v-decorator="[ 'name', {initialValue: campaignData.Name, rules: [
                    { required: true, message: 'Name is required.' },
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Campaign's description.</span>
                  </template>
                  <a-input placeholder="Campaign Description" v-decorator="['description', { initialValue: campaignData.Description, rules: [
                    { max: 512, message: 'Maximum 512 characters allowed.'}
                    ] }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Clicks Daily Limit">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Number of clicks allowed per day. The system will even out distribution of clicks throughout the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                  </template>
                  <a-input type="number" min=0 placeholder="Clicks Daily Limit"
                           v-decorator="['clicks', { initialValue: campaignData.ClicksDailyLimit }]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Daily Clicks per IP">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Daily limit of clicks per IP address. Minimum limit is 1.</span>
                  </template>
                  <a-input type="number" min=1 placeholder="Daily Clicks limit per IP address"
                           v-decorator="['limit', { initialValue: campaignData.ClicksLimitPerIp }]"/>
                </a-tooltip>
              </a-form-item>
              <div class="ant-row ant-form-item">
                <a-divider class="mt-3"></a-divider>
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                          :class="'btn btn-'+(campaignData.Status === 0 ? 'danger' : 'primary')+' px-5 ml-2'"
                          @click.prevent="toggleCampaignStatus">
                    {{ campaignData.Status == 0 ? 'Deactivate' : 'Activate' }} Campaign
                  </button>
                  <button type="button" class="btn px-5 ml-2" @click="$router.push({ name: 'xml-campaigns' })">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'

export default {
  components: {},
  computed: {
    campaignData: function () {
      let campaignData = this.$store.getters['campaign/getCampaignData'](this.$route.params.id)
      if (typeof campaignData === 'undefined') {
        this.$store.dispatch('campaign/LOAD_CAMPAIGNS')
        campaignData = {}
      }
      return campaignData
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('campaign/UPDATE_CAMPAIGN', {
            id: this.campaignData.Id,
            payload: values,
            callback: function () {
              this.$router.push({ name: 'xml-campaigns' })
            }.bind(this),
          })
        }
      })
    },
    placeholder(key) {
      return (typeof this.campaignData[key] === 'undefined') ? '' : this.campaignData[key]
    },
    toggleCampaignStatus() {
      const status = this.campaignData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change campaign status?',
        content: h => <div>{status} campaign <strong>{this.campaignData.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = this.campaignData.Status === 0 ? 1 : 0
          this.$store.dispatch('campaign/CHANGE_STATUS', {
            id: this.campaignData.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    goBack() {
      this.$router.go(-1)
    },
  },
  created() {
    this.$store.dispatch('campaign/LOAD_CAMPAIGNS')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
